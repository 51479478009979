<template>
  <form
    ref="formEl"
    v-editable="blok"
    :action="actionUrl"
    method="post"
    class="relative mx-auto flex w-full max-w-4xl flex-col gap-4 tablet:flex-row"
    aria-haspopup="dialog"
    aria-controls="call-back-success"
    aria-label="Jetzt einen Rückruf anfragen"
    @submit.prevent="formSubmitted()"
    @reset="state = { loading: false, error: false, success: false }"
  >
    <div class="grid grid-cols-3 gap-4 transition-opacity" :class="{ 'pointer-events-none opacity-50': state.success }">
      <label class="input-label col-span-3 tablet:col-span-1">
        <select
          class="w-full"
          name="salutation"
          aria-label="Anrede"
          placeholder=""
          autocomplete="honorific-prefix"
          required
          :disabled="state.success"
        >
          <option value="" disabled selected hidden>Anrede</option>
          <option value="Herr">Herr</option>
          <option value="Frau">Frau</option>
          <option value="Divers">Divers</option>
        </select>
      </label>

      <label class="input-label col-span-3 tablet:col-span-2">
        <input
          class="w-full"
          type="text"
          inputmode="text"
          name="lastname"
          aria-label="Nachname"
          placeholder=""
          autocomplete="family-name"
          required
          :disabled="state.success"
        />
        <span>Nachname</span>
      </label>

      <label class="input-label col-span-3">
        <input
          class="w-full"
          type="email"
          inputmode="email"
          name="email"
          aria-label="E-Mail-Adresse"
          placeholder=""
          autocomplete="email"
          required
          :disabled="state.success"
        />
        <span>E-Mail-Adresse</span>
      </label>

      <label class="col-span-3 flex items-start">
        <input type="checkbox" name="gdpr" required :disabled="state.success" />
        <span class="prose prose-base leading-snug dark:prose-invert" v-html="gdprText" />
      </label>

      <div class="col-span-3 mt-4">
        <audoora-button
          type="submit"
          class="button small w-full gap-8"
          :aria-disabled="state.success"
          :loading="state.loading"
          :error="state.error"
          :success="state.success"
        >
          Jetzt anmelden<nuxt-icon name="chevron-right" class="ml-8 [&>svg]:!mb-0" filled />
        </audoora-button>
      </div>
    </div>

    <div
      v-if="blok.successText || blok.successButtonText"
      id="call-back-success"
      class="prose prose-lg absolute left-1/2 top-1/2 flex w-full max-w-[min(100%,80rem)] -translate-x-1/2 -translate-y-2/3 flex-col rounded border-2 border-primary-dark bg-white p-4 text-center leading-snug dark:border-secondary dark:bg-secondary-dark"
      :aria-hidden="!state.success"
      :class="{ 'pointer-events-none opacity-0': !state.success }"
      role="alert"
      aria-live="assertive"
    >
      <p v-if="blok.successText" class="prose prose-lg whitespace-pre-wrap dark:prose-invert">
        {{ blok.successText }}
      </p>
      <button
        v-if="blok.successButtonText"
        type="reset"
        class="button small mt-4"
        :disabled="!state.success"
        :tabindex="!state.success ? -1 : 0"
      >
        {{ blok.successButtonText }}
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import type { FormSecretsStoryblok } from "~/component-types-sb"

const props = defineProps<{ blok: FormSecretsStoryblok }>()
const emits = defineEmits<{ submit: [] }>()

const actionUrl = ref("/api/form-submit/ha-secrets")
const gdprText =
  'Ich stimme zu, dass audoora mich zum Thema Hörgesundheit kontaktieren darf. Diese Einwilligung kann ich jederzeit widerrufen. Weitere Informationen finden Sie in unserer <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.'
const formSubmitCookie = useCookie<boolean>(`${actionUrl.value}-submitted`, { maxAge: 60 * 60 * 24 * 30, sameSite: true })
const formEl = ref<HTMLFormElement>()

const state = ref({
  loading: false,
  error: false,
  success: false,
})

watch(
  () => state.value.success,
  newVal => {
    if (newVal) withConsent(consent => consent.categories.functionality && (formSubmitCookie.value = true))
  }
)

async function formSubmitted() {
  try {
    state.value.loading = true

    await $fetch(actionUrl.value, {
      method: "POST",
      redirect: "manual",
      body: {
        pageName: useRoute().name,
        gdprText,
        fields: Object.fromEntries(new FormData(formEl.value)),
      },
    })
      .then(() => {
        const successUrl = props.blok.successPage?.story?.url || props.blok.successPage?.url || props.blok.successPage?.cached_url
        if (successUrl) useRouter().push(`/${successUrl}`)
      })
      .catch(() => {
        throw new Error("Form submit failed")
      })

    state.value.success = true

    metaTrack("track", "CompleteRegistration", {
      content_name: "form-three-secrets",
    })
    googleTagTrack("event", "generate_lead", {
      value: "newsletter",
      event_category: "engagement",
      event_label: "form-three-secrets",
    })

    emits("submit")
  } catch (error) {
    state.value.error = true
  } finally {
    state.value.loading = false
  }
}
</script>
